<template>
  <PopTransition appear>
    <FullScreenDialog @close="handleClose">
      <FullScreenDialogHeader ref="header" :title="title" :searchable="searchable" @search="handleSearch" />
      <FullScreenDialogContent>
        <ul class="list">
          <slot />
        </ul>
      </FullScreenDialogContent>
    </FullScreenDialog>
  </PopTransition>
</template>

<script>
import FullScreenDialog from '~/components/hwrwd/common/FullScreenDialog/FullScreenDialog.vue';
import FullScreenDialogHeader from '~/components/hwrwd/common/FullScreenDialog/FullScreenDialogHeader.vue';
import FullScreenDialogContent from '~/components/hwrwd/common/FullScreenDialog/FullScreenDialogContent.vue';
import PopTransition from '~/components/hwrwd/common/Transition/PopTransition';

export default {
  name: 'SelectListDialog',

  components: { FullScreenDialog, FullScreenDialogHeader, FullScreenDialogContent, PopTransition },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      required: false,
      default: 'Search',
    },
  },

  mounted() {
    window.addEventListener('keydown', this.handleEscape);
  },

  destroyed() {
    window.removeEventListener('keydown', this.handleEscape);
  },

  methods: {
    handleEscape(e) {
      if (e.keyCode === 27) {
        this.handleClose();
      }
    },
    handleClose() {
      this.$emit('close');
    },
    handleSearch(value) {
      this.$emit('search', value);
    },
  },
};
</script>
