<template>
  <div class="full-screen-dialog">
    <slot />
  </div>
</template>

<script>
/**
 * The WDS FullScreenDialog component. Use it with FullScreenDialogHeader and FullScreenDialogContent.
 *
 * {@link https://app.zeplin.io/project/5aa66d7915f0480150e1c403?seid=5dcd70749c67013be5624707}
 */
export default {
  name: 'FulllScreenDialog',

  provide() {
    return {
      close: this.close,
    };
  },

  mounted() {
    document.body.style.overflow = 'hidden';
  },

  destroyed() {
    document.body.style.overflow = '';
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.full-screen-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $wds-z_index-modal;
  background: $wds-color-white;
  display: flex;
  flex-direction: column;
}
</style>
