<template>
  <div
    class="select-list"
    :class="{ inline, disabled }"
    role="listbox"
    :aria-expanded="open"
    :aria-disabled="disabled"
    :aria-label="title"
  >
    <div
      class="select-list-slot-wrapper"
      tabindex="0"
      @click="toggle"
      @keydown.enter="toggle"
      role="option"
      aria-selected="false"
    >
      <slot :open="open" />
    </div>
    <template v-if="open">
      <SelectListMenu
        v-if="$device.isDesktopOrTablet"
        ref="menu"
        v-outside.click.focusin="close"
        :search-placeholder="searchPlaceholder"
        :searchable="searchable"
        @close="close"
        @search="handleSearch"
      >
        <SelectListItem
          v-for="opt in filteredOptions"
          :key="opt.label"
          :title="opt.label"
          :aria-label="opt.label"
          :selected="isSelected(opt)"
          @keydown.enter="selectOption(opt)"
          @click="selectOption(opt)"
          role="option"
        >
          <slot name="item" :item="opt">
            {{ opt.label }}
          </slot>
        </SelectListItem>
      </SelectListMenu>
      <SelectListDialog
        v-else
        ref="dialog"
        :title="title"
        :search-placeholder="searchPlaceholder"
        :searchable="searchable"
        @close="close"
        @search="handleSearch"
      >
        <SelectListItem
          v-for="opt in filteredOptions"
          :key="opt.label"
          :title="opt.label"
          :aria-label="opt.label"
          padded
          :selected="isSelected(opt)"
          @click="selectOption(opt)"
          @keydown.enter="selectOption(opt)"
          role="option"
        >
          <slot name="item" :item="opt">
            {{ opt.label }}
          </slot>
        </SelectListItem>
      </SelectListDialog>
    </template>
  </div>
</template>

<script>
import SelectListItem from '~/components/hwrwd/common/Form/SelectList/SelectListItem.vue';
import SelectListDialog from '~/components/hwrwd/common/Form/SelectList/SelectListDialog.vue';

/**
 * A flexible list menu component that be combined with any trigger element.
 * Displays a floating menu on desktop and a full screen list on mobile.
 */
export default {
  name: 'SelectList',

  components: {
    SelectListItem,
    SelectListDialog,
    SelectListMenu: /* istanbul ignore next */ () =>
      import(/* webpackMode: "lazy" */ '~/components/hwrwd/common/Form/SelectList/SelectListMenu.vue'),
  },

  props: {
    selected: {
      type: Object,
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      required: false,
      default: 'Search',
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Is the field disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filter: '',
      open: false,
    };
  },

  computed: {
    filteredOptions() {
      return this.options.filter((opt) => opt.label.toLowerCase().includes(this.filter.toLowerCase()));
    },
  },

  methods: {
    close() {
      this.open = false;
      this.filter = '';
    },
    toggle() {
      if (!this.disabled) {
        this.open = !this.open;
      }
    },
    isSelected(opt) {
      return this.selected === opt;
    },
    selectOption(opt) {
      this.$emit('select', opt);
      this.close();
    },
    handleSearch(filter) {
      this.filter = filter;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-list {
  position: relative;
  outline: none;

  &[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &.inline {
    display: inline-block;
  }

  &.disabled {
    background: #f1f2f4;
  }
}

.select-list-slot-wrapper {
  height: 100%;
}
</style>
