<template functional>
  <transition :appear="props.appear" name="pop" v-on="listeners">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'PopTransition',

  props: {
    appear: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.pop-enter-active,
.pop-leave-active {
  transition: transform 200ms cubic-bezier(0.77, 0, 0.175, 1), opacity 200ms cubic-bezier(0.77, 0, 0.175, 1);
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale3d(0.8, 0.8, 0.8);
}
</style>
