<template>
  <div :class="['header', { searchableDesktop: searchable && !$device.isMobile }]">
    <div class="main">
      <div v-if="displayBackButton" class="back">
        <Button class="close" icon @click="close">
          <icon name="core-arrow-left" />
          <span>{{ backLabel }}</span>
        </Button>
      </div>
      <slot>
        <template>
          <div v-if="searchable" :class="['search', { mobile: $device.isMobile }]">
            <div class="left">
              <button
                v-if="isSearchActive"
                class="icon back back-icon"
                :title="backLabel"
                :aria-label="backLabel"
                @keypress.enter="disableSearch"
                @click="disableSearch"
              >
                <svg-icon name="arrow" class="svg-icon arrow-left" />
              </button>

              <button
                v-else
                class="icon search-icon"
                :title="searchLabel"
                :aria-label="searchLabel"
                @keypress.enter="enableSearch"
                @click="enableSearch"
              >
                <svg-icon name="search" class="svg-icon" />
              </button>
            </div>
            <label>
              <input
                ref="search"
                type="search"
                autocomplete="off"
                :placeholder="searchPlaceholder"
                class="input"
                @input="handleSearch"
                @focus="enableSearch"
                @click="enableSearch"
                @keyup.enter="enableSearch"
              />
            </label>
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-else class="title" v-html="title" />
        </template>
      </slot>
      <div v-if="displayCloseButton">
        <span class="icon close" @keypress.enter="close" @click="close" role="button" tabindex="0">
          <svg-icon name="close" class="svg-icon" />
        </span>
      </div>
    </div>
    <div v-if="subtitle" class="sub">
      <span class="subtitle body-2">{{ subtitle }}</span>
      <a
        v-if="callToAction"
        class="cta body-2"
        @click="handleCallToAction"
        @keyup.enter="handleCallToAction"
        role="button"
        tabindex="0"
        >{{ callToAction }}</a
      >
    </div>
  </div>
</template>

<script>
/**
 * Header for the FullScreenDialog with several variations of predefined content.
 * Replace the title with custom content using the default slot.
 */
export default {
  name: 'FullScreenDialogHeader',

  props: {
    /**
     * Display a title in the header.
     */
    title: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Display a subtitle below the title.
     */
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Display a call-to-action message next to the subtitle.
     */
    callToAction: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Display a search input in place of the title.
     */
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Placeholder message for the search input.
     */
    searchPlaceholder: {
      type: String,
      required: false,
      default: 'Search',
    },
    /**
     * Display the close button as a back button.
     */
    back: {
      type: Boolean,
      required: false,
      default: false,
    },
    noClose: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Close button label.
     */
    closeLabel: {
      type: String,
      required: false,
      default: 'close',
    },
    /**
     * Back button label.
     */
    backLabel: {
      type: String,
      required: false,
      default: 'back',
    },
    /**
     * Search button label.
     */
    searchLabel: {
      type: String,
      required: false,
      default: 'search',
    },
  },

  data() {
    return {
      isSearchActive: false,
    };
  },

  computed: {
    displayBackButton() {
      return this.back && !this.isSearchActive;
    },
    displayCloseButton() {
      return !this.noClose && !this.isSearchActive;
    },
  },

  methods: {
    enableSearch() {
      this.isSearchActive = true;
    },
    disableSearch() {
      this.isSearchActive = false;
      this.$emit('search', '');
    },
    handleSearch(event) {
      this.$emit('search', event.target.value);
    },
    handleCallToAction() {
      this.$emit('cta');
    },
  },

  inject: ['close'],
};
</script>

<style lang="scss" scoped>
.header {
  box-shadow: $wds-shadow-light-s;
  padding: $wds-spacing-m;
  position: relative;
  z-index: 1;
  background: $wds-color-white;

  &.searchableDesktop {
    padding-bottom: 0;
  }

  .main {
    display: flex;
    align-items: center;
    min-height: initial;

    .title {
      flex: 1;
      font-weight: bold;
      color: $wds-color-ink-dark;
    }

    .icon {
      min-width: $wds-spacing-xxl;
      min-height: $wds-spacing-xxl;
      margin: $wds-spacing-xs $wds-spacing-s 0 0;
      color: $wds-color-ink;
      outline: none;
      cursor: pointer;
      background: transparent;
      border: 0;

      .svg-icon {
        width: $wds-spacing-m;
        height: $wds-spacing-m;
        fill: $wds-color-ink;
      }

      &:hover {
        color: $wds-color-ink;
      }

      &:focus-visible {
        box-shadow: 0 0 0 $wds-spacing-xxs $wds-color-white, 0 0 0 $wds-spacing-xs $wds-color-ink-darker;
      }
    }

    .search {
      display: flex;
      flex: 1;
      align-items: center;

      &.mobile {
        margin: 0;
      }

      .left {
        color: $wds-color-ink;
        margin-right: $wds-spacing-s;
        display: grid;
        align-self: center;
      }

      label {
        width: 100%;
      }

      .input {
        outline: none;
        width: 100%;
        flex: 1;
        border: none;
        color: $wds-color-ink-dark;
      }
    }
  }

  .sub {
    margin-top: $wds-spacing-s;
    font-size: 0;

    .subtitle {
      color: $wds-color-ink;
      margin-right: $wds-spacing-m;
    }

    .cta {
      cursor: pointer;
      color: $wds-color-orange;
      font-weight: bold;
    }
  }
}
</style>
