<template>
  <div class="content" :class="{ padded }">
    <div v-if="closable" class="close">Close</div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FullScreenDialogContent',

  props: {
    /**
     * Display a close button inside the content.
     */
    closable: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Add padding to the content.
     */
    padded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  inject: ['close'],
};
</script>

<style lang="scss" scoped>
.content {
  position: static;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  flex: 1;

  &.padded {
    padding: $wds-spacing-l;
  }

  .close {
    display: grid;
    align-self: center;
    padding: $wds-spacing-m;
    font-size: $wds-font-size-body-1;
    color: $wds-color-ink;
  }
}
</style>
