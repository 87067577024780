<template functional>
  <li
    class="item"
    role="option"
    tabindex="0"
    :aria-selected="props.selected"
    :class="{ selected: props.selected, active: props.active, padded: props.padded }"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </li>
</template>

<script>
export default {
  name: 'SelectListItem',

  props: {
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    padded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  color: $wds-color-ink;
  font-size: $wds-font-size-body-2;
  line-height: $wds-line-height-body-2;
  letter-spacing: $wds-letter-spacing-body-2;
  padding: $wds-spacing-m;
  border-top: wds-rem(1px) solid $wds-color-ink-lightest;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.padded {
    padding: $wds-spacing-l;
  }

  &.selected {
    font-weight: bold;
  }

  &.active,
  &:hover,
  &:active {
    background: $wds-color-ink-lightest;
  }

  &:focus {
    outline: none;
  }
}
</style>
